export const WIDEVINE = 'widevine';
export const PLAYREADY = 'playready';
export const FAIRPLAY = 'fairplay';

export const KEY_SYSTEMS = {
  WIDEVINE: 'com.widevine.alpha',
  PLAYREADY: 'com.microsoft.playready',
  PLAYREADY_SOFTWARE: 'com.microsoft.playready.software',
  PLAYREADY_HARDWARE: 'com.microsoft.playready.hardware',
  PLAYREADY_RECOMMENDATION: 'com.microsoft.playready.recommendation',
  PLAYREADY_RECOMMENDATION_2000: 'com.microsoft.playready.recommendation.2000',
  PLAYREADY_RECOMMENDATION_3000: 'com.microsoft.playready.recommendation.3000',
  PLAYREADY_RECOMMENDATION_ESVM: 'com.microsoft.playready.recommendation.esvm',
  FAIRPLAY: 'com.apple.fps.1_0',
} as const;

export const WIDEVINE_KEYSYSTEM = KEY_SYSTEMS.WIDEVINE;
export const FAIRPLAY_KEYSYSTEM = KEY_SYSTEMS.FAIRPLAY;
