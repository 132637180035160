import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { TOfferZone } from '@canalplus/oneplayer-types';

import { fetchConfig } from '../../config';

const { DEVICE_TYPES } = BusinessTypes;

const DEVICE_ECK: Partial<Record<BusinessTypes.DEVICE_TYPES, string>> = {
  [DEVICE_TYPES.DESKTOP_MOBILE]: 'pc',
  [DEVICE_TYPES.CHROMECAST]: 'chromecast',
  [DEVICE_TYPES.PLAYSTATION_4]: 'playstation',
  [DEVICE_TYPES.PLAYSTATION_5]: 'playstation',
  [DEVICE_TYPES.PHILIPS]: 'tvphilips',
  [DEVICE_TYPES.SAMSUNG]: 'tvsamsung',
  [DEVICE_TYPES.LG]: 'tvlg',
  [DEVICE_TYPES.HISENSE]: 'tvhisense',
  [DEVICE_TYPES.FIRETVREACT]: 'firetvreact',
  [DEVICE_TYPES.TV_KEY]: 'orangecletv',
  [DEVICE_TYPES.UWP]: 'uwp',
  [DEVICE_TYPES.XBOX]: 'xbox',
  [DEVICE_TYPES.SFR_STB7_IPTV]: 'sfrstb7iptv',
  [DEVICE_TYPES.SFR_STB8_IPTV]: 'sfrstb8iptv',
  [DEVICE_TYPES.SFR_STB8_CABLE]: 'sfrstb8cable',
};

/**
 *
 * @param offerZone the zone of the user (eg. cpafra, cppol..)
 * @param deviceType type of the device
 * @returns a promise with the HAPI headers info
 */
async function getHapiHeadersInfo(
  offerZone: TOfferZone,
  deviceType: BusinessTypes.DEVICE_TYPES,
): Promise<{ 'xx-service': string; 'xx-operator': string | undefined }> {
  const serverConfig = await fetchConfig({
    env: 'prod',
    offerZone,
    deviceType,
  });
  return {
    'xx-service': serverConfig.hapi.service,
    'xx-operator': DEVICE_ECK[deviceType],
  };
}

export default getHapiHeadersInfo;
