// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/colors.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Component
 */

.p4jgF4ouWiOZEFQOkvAg {
  display: block;
  pointer-events: none;
  fill: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite"]};
}
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/icon/Icon.css"],"names":[],"mappings":"AAEA;;EAEE;;AAEF;EACE,cAAc;EACd,oBAAoB;EACpB,kDAAgB;AAClB","sourcesContent":["@value colorWhite from \"../../../assets/styles/colors.css\";\n\n/**\n * Component\n */\n\n.Icon {\n  display: block;\n  pointer-events: none;\n  fill: colorWhite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorWhite": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite"]}`,
	"Icon": `p4jgF4ouWiOZEFQOkvAg`
};
module.exports = ___CSS_LOADER_EXPORT___;
