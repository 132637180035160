// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_ICSS_IMPORT_0___ = require("-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js!../../../assets/styles/colors.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etWzw51P3ZvnFS4leRSs svg {
    fill: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite7"]};
  }
.etWzw51P3ZvnFS4leRSs:hover:not(.isNotHoverable) svg {
    fill: ${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite"]};
  }
`, "",{"version":3,"sources":["webpack://./src/ui/components/presentational/iconbutton/IconButton.css"],"names":[],"mappings":"AAGE;IACE,kDAAiB;EACnB;AAEA;IACE,kDAAgB;EAClB","sourcesContent":["@value colorWhite7, colorWhite from \"../../../assets/styles/colors.css\";\n\n.iconButton {\n  svg {\n    fill: colorWhite7;\n  }\n\n  &:hover:not(:global(.isNotHoverable)) svg {\n    fill: colorWhite;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorWhite7": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite7"]}`,
	"colorWhite": `${___CSS_LOADER_ICSS_IMPORT_0___.locals["colorWhite"]}`,
	"iconButton": `etWzw51P3ZvnFS4leRSs`
};
module.exports = ___CSS_LOADER_EXPORT___;
