import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { getFocusableElements } from '@canalplus/oneplayer-utils';
import withTooltip from '../../../hoc/withTooltip';
var styles = require('./Button.css');
var Button = function (_a) {
    var _b;
    var ariaLabel = _a.ariaLabel, children = _a.children, uid = _a.uid, isTabKeyNavigationCapturedByPlayer = _a.isTabKeyNavigationCapturedByPlayer, testId = _a["data-testid"], id = _a.id, isBlocked = _a.isBlocked, isButtonTypeSubmit = _a.isButtonTypeSubmit, isDisabled = _a.isDisabled, isHidden = _a.isHidden, customStyle = _a.customStyle, onClick = _a.onClick, onKeyPress = _a.onKeyPress, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, trackableAction = _a.trackableAction, autoFocus = _a.autoFocus;
    var buttonRef = useRef(null);
    var _c = useState(false), isEventListenerMounted = _c[0], setIsEventListenerMounted = _c[1];
    useEffect(function () {
        var buttonRefValue = buttonRef.current;
        var key;
        var isShiftKeyPressed;
        // Get the player container
        var containerElement = document.querySelector("#oneplayer-container-".concat(uid));
        // Get all focusable elements in the player
        var keyboardFocusableElements = containerElement && getFocusableElements(containerElement);
        var handleKeyDown = function (e) {
            key = e.key;
            isShiftKeyPressed = e.shiftKey;
        };
        var handleUserKeyPress = function (e) {
            var _a;
            // If the latest key pressed was Tabulation and if the next focusable element is outside the player container
            if (key === 'Tab' &&
                e.relatedTarget instanceof Element &&
                !(containerElement === null || containerElement === void 0 ? void 0 : containerElement.contains(e.relatedTarget)) &&
                keyboardFocusableElements) {
                if (!isShiftKeyPressed) {
                    // If the focus out was made on the last element of the player
                    // Then, focus on the first focusable element
                    keyboardFocusableElements[0].focus();
                }
                else if (isShiftKeyPressed) {
                    var keyboardFocusableElementsLength = keyboardFocusableElements === null || keyboardFocusableElements === void 0 ? void 0 : keyboardFocusableElements.length;
                    // If the focus out was made on the first element of the player and the key shift was pressed in the same time than the Tabulation
                    // Then, focus on the last focusable element
                    (_a = keyboardFocusableElements[keyboardFocusableElementsLength - 1]) === null || _a === void 0 ? void 0 : _a.focus();
                }
            }
        };
        if (isTabKeyNavigationCapturedByPlayer) {
            if (!isEventListenerMounted) {
                // Add an event on keydown to catch which key was pressed
                window.addEventListener('keydown', handleKeyDown);
                // Add an event on focusout on each button
                buttonRefValue === null || buttonRefValue === void 0 ? void 0 : buttonRefValue.addEventListener('focusout', handleUserKeyPress);
                setIsEventListenerMounted(true);
            }
            return function () {
                if (isEventListenerMounted) {
                    setIsEventListenerMounted(false);
                    window.removeEventListener('keydown', handleKeyDown);
                    buttonRefValue === null || buttonRefValue === void 0 ? void 0 : buttonRefValue.removeEventListener('focusout', handleUserKeyPress);
                }
            };
        }
        return undefined;
    }, [isEventListenerMounted, isTabKeyNavigationCapturedByPlayer, uid]);
    return (_jsx("button", { hidden: !!isHidden, id: id, "aria-label": ariaLabel, "aria-disabled": isDisabled && !isBlocked, onClick: function (e) {
            // if isDisabled && !isBlocked => button is completely disabled
            // with antiAdSkip we can have isDisabled=true and isBlocked=true
            if (isDisabled && !isBlocked) {
                return;
            }
            if (onClick) {
                onClick(e);
            }
        }, onKeyPress: function (e) {
            if (!!isDisabled && !isBlocked) {
                return;
            }
            if (onKeyPress) {
                onKeyPress(e);
            }
        }, className: classnames(styles.button, customStyle, (_b = {
                // react-keys binder only filters button elements using a classname,
                // so we had to add this "disabled" class in order for react-keys to work properly
                // without this class, disabled elements can be binded and we don't want that
                disabled: isDisabled
            },
            _b[styles.disabled] = isDisabled,
            _b)), "data-testid": testId, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, ref: buttonRef, type: isButtonTypeSubmit ? 'submit' : 'button', 
        // We need the autofocus in order to let the user trigger the onClick on TvInitError
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus: autoFocus, "data-sp-action": trackableAction, children: children }));
};
export var LabelledButton = withTooltip(Button);
export default Button;
