import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from 'react-redux';
import classnames from 'classnames';
import { isMobileSelector, isTabKeyNavigationCapturedByPlayerSelector, playerUidSelector, } from '@oneplayer/selectors';
import { disabledElementTriggeredAction } from '../../../actions/TriggerElementActions';
import withTooltip from '../../../hoc/withTooltip';
import Button from '../button/Button';
import Icon from '../icon/Icon';
var defaultStyle = require('./IconButton.css');
var IconButton = function (_a) {
    var ariaLabel = _a.ariaLabel, glyph = _a.glyph, width = _a.width, height = _a.height, isDisabled = _a.isDisabled, isBlocked = _a.isBlocked, customStyle = _a.customStyle, isHoverable = _a.isHoverable, uid = _a.uid, isTabKeyNavigationCapturedByPlayer = _a.isTabKeyNavigationCapturedByPlayer, dataTestid = _a["data-testid"], onClick = _a.onClick, onEnter = _a.onEnter, onLeave = _a.onLeave, disabledElementTriggered = _a.disabledElementTriggered, trackableAction = _a.trackableAction;
    // Use default iconbutton style or use a custom one
    var style = customStyle || defaultStyle.iconButton;
    var handleTriggerDisabled = function () {
        disabledElementTriggered();
    };
    var button = (_jsx(Button, { uid: uid, ariaLabel: ariaLabel, onClick: !isDisabled ? onClick : handleTriggerDisabled, isDisabled: isDisabled, isBlocked: isBlocked, isTabKeyNavigationCapturedByPlayer: isTabKeyNavigationCapturedByPlayer, customStyle: classnames(style, {
            isNotHoverable: !isHoverable,
        }), "data-testid": dataTestid, onMouseEnter: !isDisabled ? onEnter : undefined, onMouseLeave: !isDisabled ? onLeave : undefined, trackableAction: trackableAction, children: _jsx(Icon, { glyph: glyph, width: width, height: height }) }));
    return button;
};
var mapStateToProps = function (state) { return ({
    isHoverable: !isMobileSelector(state),
    isTabKeyNavigationCapturedByPlayer: isTabKeyNavigationCapturedByPlayerSelector(state),
    uid: playerUidSelector(state),
}); };
var mapDispatchToProps = {
    disabledElementTriggered: disabledElementTriggeredAction,
};
var IconButtonConnected = connect(mapStateToProps, mapDispatchToProps)(IconButton);
export var LabelledIconButton = withTooltip(IconButtonConnected);
export default IconButtonConnected;
