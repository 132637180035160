import { ICompatibleKeySystem } from 'rx-player/experimental/tools/mediaCapabilitiesProber';

import { BusinessTypes, DrmTypes } from '@canalplus/oneplayer-constants';
import {
  ICapabilities,
  ISupportedDRM,
  TKeySystem,
  TPlatform,
  TSecurityLevels,
  TWidevineSecurityLevels,
} from '@canalplus/oneplayer-types';

import { isEdgeChromium, isWindows } from '../browser/detectorsEnv';
import { TCDM_NAME } from '../license';

const {
  WIDEVINE_KEYSYSTEM,
  FAIRPLAY_KEYSYSTEM,
  WIDEVINE,
  PLAYREADY,
  FAIRPLAY,
} = DrmTypes;
const {
  DEVICE_TYPES: {
    HISENSE,
    LG,
    PLAYSTATION_4,
    PHILIPS,
    SAMSUNG,
    DESKTOP_MOBILE,
    FIRETVREACT,
  },
  SECURITY_LEVELS: { L1, L2, L3, SL2000, SL3000 },
  PLATFORMS: { HAPI },
} = BusinessTypes;

// Robustnesses used by widevine
const WIDEVINE_ROBUSTNESSES = [
  'HW_SECURE_ALL',
  'HW_SECURE_DECODE',
  'HW_SECURE_CRYPTO',
  'SW_SECURE_DECODE',
  'SW_SECURE_CRYPTO',
];

// Robustnesses used by playready
const PLAYREADY_ROBUSTNESSES = ['3000', '2000'];

const CENC_KEY_SYSTEMS_PRIORITY_DEFAULT = [
  FAIRPLAY_KEYSYSTEM,
  'com.microsoft.playready.recommendation.esvm',
  'com.microsoft.playready.recommendation.3000',
  'com.microsoft.playready.recommendation',
  'com.microsoft.playready.recommendation.2000',
  'com.microsoft.playready.hardware',
  WIDEVINE_KEYSYSTEM,
  'com.microsoft.playready.software',
  'com.microsoft.playready',
];

const CENC_KEY_SYSTEMS_PRIORITY_DEFAULT_HDCP_NOT_SUPPORTED = [
  'com.microsoft.playready.software',
  'com.microsoft.playready',
  'com.microsoft.playready.recommendation.esvm',
  'com.microsoft.playready.recommendation',
  'com.microsoft.playready.recommendation.2000',
  'com.microsoft.playready.recommendation.3000',
  'com.microsoft.playready.hardware',
  WIDEVINE_KEYSYSTEM,
];

const singleLicensePerInitData = 'init-data';
const singleLicensePerPeriods = 'periods';

/**
 * Some devices lie or are not be able to tell us what DRM config they support (this is the case with PS4).
 * So, we have to create keySytem configuration especially for those devices.
 * For example with the PS4, when we ask for DRM availabilities, it tells us that 'com.microsoft.playready.hardware' is supported,
 * but when it comes to push the license into the CDM system or even create the challenge, it breaks :(
 * So, to bypass that lowlevel bug (from the manufacturer), we only test the ones we know that are really available.
 */
const CENC_KEY_SYSTEMS_PRIORITY_PLAYSTATION_4 = ['com.microsoft.playready'];

const CENC_KEY_SYSTEMS_PRIORITY_HISENSE = [
  'com.microsoft.playready.hardware',
  'com.microsoft.playready.software',
  'com.microsoft.playready',
];

const CENC_KEY_SYSTEMS_PRIORITY_FIRETVREACT: TKeySystem[] = [
  WIDEVINE_KEYSYSTEM,
];

const CENC_KEY_SYSTEMS_PRIORITY_LG = [
  'com.microsoft.playready.hardware',
  'com.microsoft.playready.software',
  'com.microsoft.playready',
];

const CENC_KEY_SYSTEMS_PRIORITY_PHILIPS = [
  'com.microsoft.playready.hardware',
  'com.microsoft.playready.software',
  'com.microsoft.playready',
];

const CENC_KEY_SYSTEMS_PRIORITY_SAMSUNG = [
  'com.microsoft.playready.hardware',
  'com.microsoft.playready.software',
  'com.microsoft.playready',
];

const CENC_KEY_SYSTEMS_PRIORITY_WINDOWS_EDGE_CHROMIUM = [
  'com.microsoft.playready.hardware',
  'com.microsoft.playready.recommendation.3000',
  'com.microsoft.playready.recommendation',
  'com.microsoft.playready.recommendation.2000',
  WIDEVINE_KEYSYSTEM,
  'com.microsoft.playready.software',
  'com.microsoft.playready',
  'com.microsoft.playready.recommendation.esvm',
  FAIRPLAY_KEYSYSTEM,
];

/**
 * @param platform type of content we play
 * @returns whether Drm is needed or not
 */
export function isDrmNeeded(platform: string): boolean {
  return !['directfile'].includes(platform);
}

/**
 *
 * @param keySystem option allowing to communicate your decryption-related preferences
 * @returns if the current CDM need a server certificate.
 */
export function isDrmNeedsCertificate(keySystem: string): boolean {
  return keySystem === FAIRPLAY || keySystem === WIDEVINE;
}

/**
 * @param mimeType appropriate mime-type for the media
 * @returns convert mimeType to Drm Name
 */
export function keySystemToDrmName(mimeType: TKeySystem): TCDM_NAME {
  const DRM_MIME_TYPES_MAP = {
    'com.widevine.alpha': WIDEVINE,
    'com.microsoft.playready.software': PLAYREADY,
    'com.microsoft.playready.hardware': PLAYREADY,
    'com.microsoft.playready.recommendation.esvm': PLAYREADY,
    'com.microsoft.playready.recommendation.3000': PLAYREADY,
    'com.microsoft.playready.recommendation': PLAYREADY,
    'com.microsoft.playready.recommendation.2000': PLAYREADY,
    'com.microsoft.playready': PLAYREADY,
    'com.apple.fps.1_0': FAIRPLAY,
  };
  return DRM_MIME_TYPES_MAP[mimeType] as TCDM_NAME;
}

/**
 * We only need robustnesses on widevine CDM
 * @param keySystem option allowing to communicate your decryption-related preferences
 * @returns robustnesses
 */
export function getDRMRobustnesses(
  keySystem: TKeySystem,
): string[] | undefined[] {
  const keySystemName = keySystemToDrmName(keySystem);
  if (keySystemName === 'widevine') {
    return WIDEVINE_ROBUSTNESSES;
  }
  if (keySystem === 'com.microsoft.playready.recommendation') {
    return PLAYREADY_ROBUSTNESSES;
  }
  return [undefined];
}

type TKeyPerLicense = 'mkpl' | 'skpl';

/**
 *
 * @param keyPerLicense type of key per License
 * @param drmEnv name of DRM environement
 * @param platform type of content we play
 * @returns get single License per periods or per init data
 */
export function getSingleLicensePer(
  keyPerLicense: TKeyPerLicense = 'skpl',
  drmEnv: TCDM_NAME,
  platform: TPlatform,
): typeof singleLicensePerPeriods | typeof singleLicensePerInitData {
  if (
    drmEnv !== FAIRPLAY &&
    !(isWindows() && isEdgeChromium() && platform === HAPI) &&
    keyPerLicense === 'mkpl'
  ) {
    return singleLicensePerPeriods;
  }
  return singleLicensePerInitData;
}

/**
 * Get Widevine security level from robustnesses (L1,L2,L3)
 * @param robustnesses robustness values
 * @returns L1|L2|L3
 */
export function getWidevineSecurityLevel(
  robustnesses: string[],
): TWidevineSecurityLevels {
  let hasHardwareDecode = false;
  let hasHardwareDecrypt = false;
  for (let i = 0, len = robustnesses.length; i < len; i += 1) {
    const robustness = robustnesses[i];
    if (robustness === 'HW_SECURE_ALL') {
      hasHardwareDecode = true;
      hasHardwareDecrypt = true;
      break;
    } else if (robustness === 'HW_SECURE_DECODE') {
      hasHardwareDecode = true;
    } else if (robustness === 'HW_SECURE_CRYPTO') {
      hasHardwareDecrypt = true;
    }
  }

  if (hasHardwareDecrypt) {
    return hasHardwareDecode ? 'L1' : 'L2';
  }
  return 'L3';
}

/**
 * @param keySystemConfiguration keySystem config
 * @returns valid array of robustness
 */
export function getRobustnessesFromKeySystemConfiguration(
  keySystemConfiguration: MediaKeySystemConfiguration,
): string[] {
  if (keySystemConfiguration === undefined) {
    return [];
  }

  const { videoCapabilities } = keySystemConfiguration;
  if (videoCapabilities === undefined) {
    return [];
  }
  const hashMap: { [prop: string]: boolean } = {};
  const capabilities: string[] = [];
  for (let i = 0, len = videoCapabilities.length; i < len; i += 1) {
    const capability = videoCapabilities[i];
    if (
      typeof capability.robustness === 'string' &&
      capability.robustness !== '' &&
      hashMap[capability.robustness] !== true
    ) {
      hashMap[capability.robustness] = true;
      capabilities.push(capability.robustness);
    }
  }
  return capabilities;
}

/**
 * @param keySystem option allowing to communicate your decryption-related preferences
 * @param shouldPersistLicense config param whether or not we should persist license
 * @param noCapabilities When set to `true`, do not set the `audioCapabilities` and
 * `videoCapabilities` in the returned `MediaKeySystemConfiguration`. Those very
 * rarely happen to be buggy browser-side.
 * @returns key System Configurations
 */
export function getKeySystemConfigurations(
  keySystem: TKeySystem,
  shouldPersistLicense?: boolean,
  noCapabilities?: boolean,
): MediaKeySystemConfiguration {
  let videoCapabilities: ICapabilities[] | undefined;
  let audioCapabilities: ICapabilities[] | undefined;
  if (noCapabilities !== true) {
    const actualVideoCapabilities: ICapabilities[] = [];
    const actualAudioCapabilities: ICapabilities[] = [];

    const robustnesses = getDRMRobustnesses(keySystem);
    robustnesses.forEach((robustness: string | undefined) => {
      actualVideoCapabilities.push({
        contentType: 'video/mp4;codecs="avc1.4d401e"', // standard mp4 codec
        robustness,
      });
      actualVideoCapabilities.push({
        contentType: 'video/mp4;codecs="avc1.42e01e"',
        robustness,
      });
      actualVideoCapabilities.push({
        contentType: 'video/webm;codecs="vp8"',
        robustness,
      });
      actualAudioCapabilities.push({
        contentType: 'audio/mp4;codecs="mp4a.40.2"', // standard mp4 codec
        robustness,
      });
    });
    videoCapabilities = actualVideoCapabilities;
    audioCapabilities = actualAudioCapabilities;
  }

  return {
    initDataTypes: ['cenc'],
    videoCapabilities,
    audioCapabilities,
    label: keySystem,
    /**
     * Both properties here, are mandatory in order to detect a CDM inside the PS5.
     * We use the following keySytem, because, it is specific PS5 and playready.
     */
    ...(shouldPersistLicense &&
      [
        'com.microsoft.playready.recommendation.esvm',
        'com.microsoft.playready.recommendation.3000',
        'com.microsoft.playready.recommendation.2000',
        'com.microsoft.playready.recommendation',
      ].includes(keySystem) && {
        distinctiveIdentifier: 'required',
        sessionTypes: ['temporary', 'persistent-license'],
      }),
  };
}

/**
 * @param drmA first Drm we want to compare
 * @param drmB second Drm we want to compare
 * @param deviceType type of the device
 * @param hdcpContext hdcp context
 * @param CENC_KEY_SYSTEMS_PRIORITY CENC_KEY_SYSTEMS_PRIORITY
 * @param platform type of content we play
 * @returns Drm by priority
 */
export function drmsCompareByPriority(
  drmA: TKeySystem,
  drmB: TKeySystem,
  deviceType: BusinessTypes.DEVICE_TYPES,
  hdcpContext: string[] | null,
  CENC_KEY_SYSTEMS_PRIORITY?: string[],
  platform?: TPlatform,
): number {
  // if we receive from onlineConfig an array of priorities
  // we take this one instead of the default ones defined
  // with deviceType.
  if (CENC_KEY_SYSTEMS_PRIORITY) {
    return (
      CENC_KEY_SYSTEMS_PRIORITY.indexOf(drmA) -
      CENC_KEY_SYSTEMS_PRIORITY.indexOf(drmB)
    );
  }

  if (hdcpContext && hdcpContext.length === 0) {
    return (
      CENC_KEY_SYSTEMS_PRIORITY_DEFAULT_HDCP_NOT_SUPPORTED.indexOf(drmA) -
      CENC_KEY_SYSTEMS_PRIORITY_DEFAULT_HDCP_NOT_SUPPORTED.indexOf(drmB)
    );
  }

  if (isWindows() && isEdgeChromium() && platform === HAPI) {
    return (
      CENC_KEY_SYSTEMS_PRIORITY_WINDOWS_EDGE_CHROMIUM.indexOf(drmA) -
      CENC_KEY_SYSTEMS_PRIORITY_WINDOWS_EDGE_CHROMIUM.indexOf(drmB)
    );
  }

  switch (deviceType) {
    case HISENSE:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_HISENSE.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_HISENSE.indexOf(drmB)
      );

    case FIRETVREACT:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_FIRETVREACT.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_FIRETVREACT.indexOf(drmB)
      );

    case LG:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_LG.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_LG.indexOf(drmB)
      );

    case PLAYSTATION_4:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_PLAYSTATION_4.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_PLAYSTATION_4.indexOf(drmB)
      );

    case PHILIPS:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_PHILIPS.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_PHILIPS.indexOf(drmB)
      );

    case SAMSUNG:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_SAMSUNG.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_SAMSUNG.indexOf(drmB)
      );

    default:
      return (
        CENC_KEY_SYSTEMS_PRIORITY_DEFAULT.indexOf(drmA) -
        CENC_KEY_SYSTEMS_PRIORITY_DEFAULT.indexOf(drmB)
      );
  }
}

interface ISortDrmContextListOptions {
  drmContextList: ISupportedDRM[];
  deviceType: BusinessTypes.DEVICE_TYPES;
  hdcpContext: string[] | null;
  CENC_KEY_SYSTEMS_PRIORITY?: string[];
  platform?: TPlatform;
}
/**
 * @param arg0 the object containing drmContextList, deviceType, hdcpContext, CENC_KEY_SYSTEMS_PRIORITY and platform
 * @param arg0.drmContextList List of Drm Context
 * @param arg0.deviceType type of the device
 * @param arg0.hdcpContext Hdcp context
 * @param arg0.CENC_KEY_SYSTEMS_PRIORITY CENC_KEY_SYSTEMS_PRIORITY
 * @param arg0.platform type of content we play
 * @returns list of Drm context ordered
 */
export function sortDrmContextList({
  drmContextList,
  deviceType,
  hdcpContext,
  CENC_KEY_SYSTEMS_PRIORITY,
  platform,
}: ISortDrmContextListOptions): ISupportedDRM[] {
  return drmContextList.sort((drmContextA, drmContextB) =>
    drmsCompareByPriority(
      drmContextA.keySystem,
      drmContextB.keySystem,
      deviceType,
      hdcpContext,
      CENC_KEY_SYSTEMS_PRIORITY,
      platform,
    ),
  );
}

/**
 * @param deviceType type of the device
 * @param shouldPersistLicense config param whether or not we should persist license
 * @returns the necessary configuration for getCompatibleDRMConfigurations() Prober tool
 */
export function constructConfig(
  deviceType: BusinessTypes.DEVICE_TYPES,
  shouldPersistLicense?: boolean,
): Array<{
  type: string;
  configuration: MediaKeySystemConfiguration;
}> {
  switch (deviceType) {
    case PHILIPS:
      return CENC_KEY_SYSTEMS_PRIORITY_PHILIPS.map((keySystem) => ({
        type: keySystem,
        configuration: getKeySystemConfigurations(keySystem as TKeySystem),
      }));

    case HISENSE:
      return CENC_KEY_SYSTEMS_PRIORITY_HISENSE.map((keySystem) => ({
        type: keySystem,
        configuration: getKeySystemConfigurations(
          keySystem as TKeySystem,
          shouldPersistLicense,
        ),
      }));

    case LG:
      return CENC_KEY_SYSTEMS_PRIORITY_LG.map((keySystem) => ({
        type: keySystem,
        configuration: getKeySystemConfigurations(
          keySystem as TKeySystem,
          shouldPersistLicense,
        ),
      }));

    case PLAYSTATION_4:
      return CENC_KEY_SYSTEMS_PRIORITY_PLAYSTATION_4.map((keySystem) => ({
        type: keySystem,
        configuration: getKeySystemConfigurations(
          keySystem as TKeySystem,
          shouldPersistLicense,
        ),
      }));

    case SAMSUNG:
      return CENC_KEY_SYSTEMS_PRIORITY_SAMSUNG.map((keySystem) => ({
        type: keySystem,
        configuration: getKeySystemConfigurations(
          keySystem as TKeySystem,
          shouldPersistLicense,
        ),
      }));

    case DESKTOP_MOBILE:
      return [
        ...CENC_KEY_SYSTEMS_PRIORITY_DEFAULT.map((keySystem) => ({
          type: keySystem,
          configuration: getKeySystemConfigurations(
            keySystem as TKeySystem,
            shouldPersistLicense,
          ),
        })),

        // EME API (DRM on browsers) implementation are known to be buggy.
        // Here we will propose a second iteration with no asked "capabilities"
        // just in case the browser's implementation refuses the ones we ask
        // for.
        ...CENC_KEY_SYSTEMS_PRIORITY_DEFAULT.map((keySystem) => ({
          type: keySystem,
          configuration: getKeySystemConfigurations(
            keySystem as TKeySystem,
            shouldPersistLicense,
            true,
          ),
        })),
      ];

    default:
      return [
        ...CENC_KEY_SYSTEMS_PRIORITY_DEFAULT.map((keySystem) => ({
          type: keySystem,
          configuration: getKeySystemConfigurations(
            keySystem as TKeySystem,
            shouldPersistLicense,
          ),
        })),
      ];
  }
}

/**
 * @param drmConfigs drm configs
 * @returns the correct drm supported on the current browser given an array of MediaKeySystemConfiguration object.
 */
export function determineSupportedDrm(
  drmConfigs: ICompatibleKeySystem[],
): ISupportedDRM[] {
  return drmConfigs.reduce<ISupportedDRM[]>((acc, currentConfig) => {
    if (currentConfig.compatibleConfiguration) {
      const name = keySystemToDrmName(currentConfig.type as TKeySystem);
      const robustnesses = getRobustnessesFromKeySystemConfiguration(
        currentConfig.compatibleConfiguration,
      );
      acc.push({
        keySystem: currentConfig.type as TKeySystem,
        name,
        robustnesses,
        ...(name === WIDEVINE && {
          securityLevel: getWidevineSecurityLevel(robustnesses),
        }),
      });
    }
    return acc;
  }, []);
}

/**
 * @param deviceType type of device
 * @description Safari that implement Fairplay doesnt support requestMediaKeySystemAccess API,
 * instead, we have a direct access to the media keys thanks to an other API
 * @returns if the fairplay DRM is implemented on the browser
 */
export function isFairplayDrmSupported(
  deviceType: BusinessTypes.DEVICE_TYPES,
): boolean {
  if (deviceType === PLAYSTATION_4) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const safariMediaKeys = window.WebKitMediaKeys;

  return (
    safariMediaKeys &&
    safariMediaKeys.isTypeSupported &&
    safariMediaKeys.isTypeSupported(FAIRPLAY_KEYSYSTEM, 'video/mp4')
  );
}

/**
 * @param robustness value of robustness
 * @returns the security level depending on the robustness
 */
export function getSecurityLevelFromRobustness(
  robustness: string,
): TSecurityLevels | undefined {
  switch (robustness) {
    case WIDEVINE_ROBUSTNESSES[0]:
      return L1;
    case WIDEVINE_ROBUSTNESSES[1]:
      return L2;
    case WIDEVINE_ROBUSTNESSES[2]:
      return L2;
    case WIDEVINE_ROBUSTNESSES[3]:
      return L3;
    case WIDEVINE_ROBUSTNESSES[4]:
      return L3;
    case PLAYREADY_ROBUSTNESSES[0]:
      return SL3000;
    case PLAYREADY_ROBUSTNESSES[1]:
      return SL2000;
    default:
      return undefined;
  }
}
